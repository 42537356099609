import React, {useContext, useState, useEffect, useMemo, useLayoutEffect, useRef } from "react";
import "./Themes.scss";
import {
  Link,
} from "react-router-dom";
import { ModelContext } from "providers/ModelProvider";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, {
  Navigation
} from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import Conf from 'Conf';
import PlayerConf from 'shared/Conf';
import { MixContext } from "shared/providers/MixProvider";

SwiperCore.use([Navigation]);

function Themes() {
  const { triggerSon,mixReady,setContext } = useContext(MixContext);
  const { getCollection } = useContext(ModelContext);
  const [ themeId,setThemeId ] = useState(null);
  const themes=useMemo(()=>getCollection('theme'),[getCollection]);
  const portraits=useMemo(()=>getCollection('portrait'),[getCollection]);
  useEffect(()=>{
    setContext({type:'themes'});
    return ()=>{
      setContext(null);
    }
  },[setContext]);
  useEffect(()=>{
    if (themes.length>0) setThemeId(themes[0]._id);
  },[themes,setThemeId])
  const handleSlideChange=(swiper)=>{
    if (themes.length>0) setThemeId(themes[(swiper.activeIndex) % themes.length]._id);
  }
  const list=portraits.filter((o)=>o.themeId===themeId);
  const container = useRef(null);
  const [ scale, setScale ] = useState(1);
  const [ left, setLeft ] = useState(1);
  const [ top, setTop ] = useState(1);
  useLayoutEffect(()=>{
    const handleResize=()=>{
      if (container.current) {
        const { width,height } = container.current.getBoundingClientRect();
        //console.log(width,height);
        const ratio=height/width;
        const R=PlayerConf.height/PlayerConf.width;
        let scale=1;
        let l=0;
        let t=0;
        if (ratio<R) {
          scale=height/PlayerConf.height;
          l=(width-PlayerConf.width*scale)/2;
        } else {
          scale=width/PlayerConf.width;
          t=(height-PlayerConf.height*scale)/2;
        }
        //console.log(width,height,PlayerConf.width,PlayerConf.height,scale)
        setScale(scale);
        setLeft(l);
        setTop(t);
        const root = document.documentElement;
        root.style.setProperty('--pixelSize', (1.2/scale)+"px");
      }
    }
    window.addEventListener('resize',handleResize);
    handleResize();
    return ()=>{
      window.removeEventListener('resize',handleResize);
    }
  },[setScale,setLeft]);
  useEffect(()=>{
    if (mixReady) {
      triggerSon('themes');
    }
  },[mixReady,triggerSon]);
  useEffect(()=>{
    if (mixReady && themeId) {
      triggerSon('theme/'+themeId);
    }
  },[themeId,mixReady,triggerSon]);
  return <div className='themes' ref={container}>
    <div className='themes-mask'></div>
    <div className='themes-inner' style={{left,top,transform:'scale('+scale+')'}}>
      <div className='themes-chooser'>
          <Swiper
            navigation
            spaceBetween={0}
            slidesPerView={1}
            onSlideChange={handleSlideChange}
          >
            {themes.map((theme)=>{
              const ps=portraits.filter((o)=>o.themeId===theme._id);
              return ps.length>0 ? <SwiperSlide key={theme._id}>
                <div className="theme-inner">
                {theme.titre}
                </div>
              </SwiperSlide> : null
            })}
          </Swiper>
      </div>
      <div className='portraits-chooser'>
          <Swiper
            key={themeId}
            navigation
            spaceBetween={0}
            slidesPerView={3}
            initialSlide={list.length>2 ? 1 : 0}
            centeredSlides={list.length>2}
            centerInsufficientSlides={list.length<=2}
          >
            {list.map((portrait)=><SwiperSlide key={portrait._id}>
              <Link to={'/player/'+portrait._id}>
              <div className='portrait-slide'>
                {portrait.photo[0] && <div className='portrait-photo' style={{backgroundImage:'url('+Conf.filesUrl+portrait.photo[0].url+')'}}/>}
                <div className='portrait-nom'>{portrait.nom}</div>
              </div>
              </Link>
            </SwiperSlide>)}
          </Swiper>
      </div>
    </div>
  </div>;
}

export default Themes;
