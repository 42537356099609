import React from "react";
import "./Loader.scss";

function Loader({loaded}) {
  return <div className='loader'>
    <div className="loader-bar-container">
      <div className="loader-bar" style={{width:loaded+'%'}}/>
    </div>
  </div>;
}

export default Loader;
