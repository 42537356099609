import React, {useLayoutEffect, useContext} from "react";
import './App.css';
import 'shared/styles.scss';
import PlayerConf from 'shared/Conf';

import Landing from 'components/Landing';
import Themes from 'components/Themes';
import Home from 'components/Home';
import Loader from 'components/Loader';
import Player from 'components/Player';
import Lettre from 'components/Lettre';
import { MixContext } from "shared/providers/MixProvider";

import {
  Routes,
  Route,
} from "react-router-dom";

function App() {
  const { loaded,mixReady } = useContext(MixContext);
  useLayoutEffect(()=>{
    const root = document.documentElement;
    PlayerConf.css.forEach(rule => root.style.setProperty('--'+rule.key, rule.value));
  },[]);
  return (
    <>
      {mixReady ?
        <Routes>
          <Route path="/themes" element={<Themes />} />
          <Route path="/lettre/:id" element={<Lettre />} />
          <Route path="/player/:id" element={<Player />} />
          <Route path="/dev" element={<Home/>} />
          <Route path="/" element={<Landing />} />
          <Route path="*" element={<Landing />} />
        </Routes>
      : <Loader loaded={loaded}/> }
    </>
  );
}

export default App;
