import React, { useState, createContext } from "react";

export const SettingsContext = createContext({});

const SettingsProvider = ({children})=>{
  const [ appState,setAppState ] = useState({});
  return (
        <SettingsContext.Provider value={{appState,setAppState}}>
            {children}
        </SettingsContext.Provider>
    );
}
export default SettingsProvider;
