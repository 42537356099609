import React, {useState} from 'react';
import "./Lien.scss";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, {
  Pagination
} from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/modules/pagination/pagination.scss';
import Conf from 'Conf';
import { Scrollbars } from 'react-custom-scrollbars-2';

SwiperCore.use([Pagination]);

const Lien = ({lien,onClose}) => {
  const [ preventClick,setPreventClick] = useState(false);
  let classes='lien-mask';
  if (lien) {
    classes+=' on';
    if (lien.images.length>0) classes+=' has-images';
  } else classes+=' off';
  const prevent=(e)=>e.stopPropagation();
  const handleClose=(e)=>{
    if (!preventClick) onClose(e);
  }
  return <div className={classes} onClick={handleClose}>
      <div className='lien-inner' onClick={prevent}>
        {lien && <div className='lien-content'>
          <Scrollbars
          style={{ position:'absolute', width: '100%', height: '100%' }}
          autoHide
          >
            <div className='lien-content-inner'>
              <h3>{lien.titre}</h3>
              <div>{lien.texte}</div>
            </div>
          </Scrollbars>
        </div>}
        {lien && lien.images.length>0 && <div className='lien-images'>
        <Swiper
          pagination={{ clickable: true, type:'bullets' }}
          spaceBetween={0}
          slidesPerView={1}
          onTouchStart={()=>setPreventClick(true)}
          onTouchEnd={()=>setTimeout(()=>setPreventClick(false),10)}
        >
          {lien.images.map((img,i)=><SwiperSlide key={img.url+'-'+i}><img src={Conf.filesUrl+img.url} alt=""/></SwiperSlide>)}
        </Swiper>
        </div>}
      </div>
      <div className='lien-close-btn'></div>
    </div>;
}
export default Lien;
