import React, {useState,createContext,useContext,useEffect,useCallback,useMemo} from 'react';
import Conf from 'Conf';
import { ModelContext } from "providers/ModelProvider";
import Player from 'shared/providers/Player';
import Preload from 'preload-it';

export const MixContext = createContext({});

const audioContext = new (window.AudioContext || window.webkitAudioContext)();
const masterMute = audioContext.createGain();
masterMute.connect(audioContext.destination);
const master = audioContext.createGain();
master.connect(masterMute);
const players={};
const files=[];
let cachedSons=[];
const preload = Preload();
preload.onerror = item => {
  console.log(item);
}

const fadeIn=(son,listener)=>{
  const player=players[listener.track] ? players[listener.track][son._id] : null;
  if (player) {
    console.log('fadein',player.titre);
    if (listener.track==='track-0') {
      const clone=new Player({
        audioContext,
        master,
        src: player.main.src,
        loop:player.main.loop,
        volume:player.main.volume,
      });
      clone.status=null;
      player.clones.push(clone);
      clone.once('end',()=>{
        const idx=player.clones.indexOf(clone);
        player.clones.splice(idx,1);
      });
      if (player.fadeIn){
        clone.volume=0;
        clone.play();
        clone.fade(player.volume);
      } else {
        clone.volume=player.volume;
        clone.play();
      }
    } else {
      Object.keys(players[listener.track]).forEach((sonId, i) => {
        if (sonId!==son._id) {
          let p=players[listener.track][sonId];
          if (p.main.playing) {
            p.main.status='fadingOut';
            p.main.off('load');
            p.main.off('fade');
            p.main.fade(0);
            p.main.once('fade',()=>{
              p.main.status=null;
              p.main.stop();
            });
          }
        }
      });
      player.main.off('load');
      player.main.off('fade');
      if (!player.main.playing) {
        player.main.status=null;
        if (player.fadeIn){
          //console.log('setVolume');
          player.main.volume=0;
          //console.log('play');
          player.main.play();
          //console.log('fade');
          player.main.fade(player.volume);
        } else {
          //console.log('setVolume',player.volume);
          player.main.volume=player.volume;
          //console.log('play');
          player.main.play();
        }
      } else {
        player.main.status=null;
        //console.log('fade');
        player.main.fade(player.volume);
      }
    }
  }
};
const fadeOut=(son,listener,stop=true)=>{
  const player=players[listener.track] ? players[listener.track][son._id] : null;
  if (player) {
    if (!player.loaded) {
      player.main.once('load',player.main.stop);
    }
    if (listener.track==='track-0') {
      player.clones.forEach((clone, i) => {
        if (clone.status!=='fadingOut') {
          console.log('fadeOut',player.titre);
          //console.log('fadeOut',clone);
          clone.status='fadingOut';
          clone.off('fade');
          clone.fade(0);
          clone.once('fade',()=>{
            clone.status=null;
            clone.stop();
          });
        }
      });
    } else {
      if (player.main.playing) {
        if (player.main.status!=='fadingOut') {
          console.log('fadeOut',player.titre);
          player.main.status='fadingOut';
          player.main.off('fade');
          player.main.fade(0);
          if (stop) player.main.once('fade',()=>{
            player.main.status=null;
            player.main.stop();
          });
        }
      }
    }
  }
};
let context=null;

const MixProvider = ({children}) => {
  const { getCollection } = useContext(ModelContext);
  const [ mixReady, setMixReady ] = useState(false);
  const [ muteAll, setMuteAll ] = useState(false);
  const [ loaded, setLoaded ] = useState(0);
  const [ volume, setVolume ] = useState(0);
  const mute=()=>{
    masterMute.gain.cancelScheduledValues(audioContext.currentTime);
    masterMute.gain.setValueAtTime(masterMute.gain.value, audioContext.currentTime);
    masterMute.gain.linearRampToValueAtTime(0, audioContext.currentTime+0.5);
  }
  const unMute=()=>{
    masterMute.gain.cancelScheduledValues(audioContext.currentTime);
    masterMute.gain.setValueAtTime(masterMute.gain.value, audioContext.currentTime);
    masterMute.gain.linearRampToValueAtTime(1, audioContext.currentTime+0.5);
  }
  useEffect(()=>{
    window.addEventListener('blur',mute);
    window.addEventListener('focus',unMute);
    return ()=>{
      window.removeEventListener('blur',mute);
      window.removeEventListener('focus',unMute);
    }
  },[]);
  const sons=useMemo(()=>getCollection('son'),[getCollection]);
  const settings=useMemo(()=>getCollection('settings'),[getCollection]);
  const conversations=useMemo(()=>getCollection('conversation'),[getCollection]);
  const messages=useMemo(()=>getCollection('message'),[getCollection]);
  const lettres=useMemo(()=>getCollection('lettre'),[getCollection]);
  const themes=useMemo(()=>getCollection('theme'),[getCollection]);
  const triggerSon=useCallback((hook)=>{
    console.log(hook);
    const toTrigger=[hook];
    const tab=hook.split('/');
    if (tab[0]==='lettre' && tab.length===2) {
      toTrigger.push('lettre');
    }
    if (tab[0]==='theme' && tab.length===2) {
      toTrigger.push('theme');
    }
    if (tab[0]==='conversation' && tab.length===2) {
      const conversation=conversations.find((o)=>o._id===tab[1]);
      toTrigger.push('portrait/'+conversation.portraitId+'/conversation');
      toTrigger.push('conversation');
    }
    if (tab[0]==='message' && tab.length===2) {
      const message=messages.find((o)=>o._id===tab[1]);
      const conversation=conversations.find((o)=>o._id===message.conversationId);
      toTrigger.push('conversation/'+conversation._id+'/message');
      toTrigger.push('portrait/'+conversation.portraitId+'/message');
      toTrigger.push('message');
    }
    cachedSons.forEach((son, i) => {
      son.listeners.forEach((listener, i) => {
        if(toTrigger.indexOf(listener.hook)!==-1) {
          console.log(son.titre,listener);
          if (listener.track==='track-0') fadeIn(son,listener);
          else {
            if (listener.action==='play') {
              fadeIn(son,listener);
            }
            if (listener.action==='pause') {
              fadeOut(son,listener,true);
            }
            if (listener.action==='stop') {
              fadeOut(son,listener);
            }
          }
        }
      });
    });
  },[conversations,messages]);
  preload.oncomplete = items => {
    sons.forEach((son, i) => {
      if (son.fichier.length>0) {
        let path=Conf.filesUrl+son.fichier[0].url;
        const item=items.find((o)=>o.url===path);
        son.listeners.forEach((listener, i) => {
          if (!players[listener.track]) players[listener.track]={};
          if (!players[listener.track][son._id]) {
            players[listener.track][son._id]={
              main:new Player({
                audioContext,
                master,
                src: item.blobUrl,
                loop:Boolean(son.loop),
                volume:isNaN(son.volume) ? 1 : son.volume,
                onplayerror:(err)=>{
                  console.log('play error',err);
                }
              }),
              item:item,
              titre:son.titre,
              url:son.fichier[0].url,
              volume:isNaN(son.volume) ? 1 : son.volume,
              fadeIn:Boolean(son.fadeIn),
              clones:[],
            };
          }
        });
      }
    });
    console.log('players updated',players);
    setMixReady(true);
  }
  preload.onprogress = event => {
    setLoaded(event.progress);
    //console.log(event.progress + '%');
  }
  preload.onfetched = item => {
    //console.log(item);
  }

  useEffect(()=>{
    sons.forEach((son, i) => {
      son.listeners.forEach((listener, i) => {
        if (players[listener.track] && players[listener.track][son._id]){
          let player=players[listener.track][son._id];
          let volume=isNaN(son.volume) ? 1 : son.volume;
          if (player.volume!==volume) {
            console.log('update Volume');
            player.volume=volume;
            if (player.main.status!=='fadingOut') player.main.fade(volume);
            player.clones.forEach((clone, i) => {
              if (clone.status!=='fadingOut') clone.fade(volume);
            });
          }
          if (player.loop!==son.loop) {
            console.log('update Loop');
            player.loop=son.loop;
            player.main.loop=player.loop;
            player.clones.forEach((clone, i) => {
              clone.loop(player.loop);
            });
          }
          if (player.fadeIn!==son.fadeIn) {
            console.log('update FadeIn');
            player.fadeIn=Boolean(son.fadeIn);
          }
          if (son.fichier.length>0) {
            if (player.url!==son.fichier[0].url) {
              console.log('url changed');
              player.main.stop();
              player.clones.forEach((clone, i) => {
                clone.stop();
              });
              let path=Conf.filesUrl+player.url;
              let pathIdx=files.indexOf(path);
              if (pathIdx!==-1) files.splice(pathIdx,1);
              delete players[listener.track][son._id];
            }
          } else {
            console.log('fichier supprimé');
            player.main.stop();
            player.clones.forEach((clone, i) => {
              clone.stop();
            });
            let path=Conf.filesUrl+player.url;
            let pathIdx=files.indexOf(path);
            if (pathIdx!==-1) files.splice(pathIdx,1);
            delete players[listener.track][son._id];
          }
        }
      });
    });
    if (sons.length>0) {
      cachedSons=[...sons];
      const newFiles=[];
      sons.forEach((son, i) => {
        if (son.fichier.length>0) {
          let path=Conf.filesUrl+son.fichier[0].url;
          if (files.indexOf(path)===-1) {
            files.push(path);
            newFiles.push(path);
          }
        }
      });
      if (newFiles.length>0) {
        setMixReady(false);
        preload.fetch(newFiles);
      }
    }
  },[sons,setMixReady]);
  const setContext=useCallback((c)=>{
    console.log('context',c);
    if (
      (
        c!==null && (
          context===null
          || c.type!==context.type
          || c.id!==context.id
        )
      )
      || (c===null && context!==null)
      ) {
      context=c;
      const aSons=[];
      if (context) {
        if (context.type==='portrait') {
          const portraitConversations=conversations.filter((o)=>o.portraitId===context.id);
          let messagesAll=[];
          portraitConversations.forEach((conversation) => {
            const conversationMessages=messages.filter((o)=>o.conversationId===conversation._id);
            messagesAll=[...messagesAll,...conversationMessages];
          });
          cachedSons.forEach((son)=>{
            son.listeners.forEach((listener, i) => {
              const tab=listener.hook.split('/');
              let test=false;
              if (tab[0]==='portrait' && tab[1]===context.id) test=true;
              if (tab[0]==='conversation' && portraitConversations.findIndex((o)=>o._id===tab[1])!==-1) test=true;
              if (tab[0]==='message' && messagesAll.findIndex((o)=>o._id===tab[1])!==-1) test=true;
              if (test) aSons.push({sonId:son._id,listenerId:listener._id});
            });
          });
        }
        if (context.type==='themes') {
          cachedSons.forEach((son)=>{
            son.listeners.forEach((listener, i) => {
              const tab=listener.hook.split('/');
              let test=false;
              if (tab[0]==='themes') test=true;
              if (tab[0]==='theme') test=true;
              if (tab[0]==='theme' && themes.findIndex((o)=>o._id===tab[1])!==-1) test=true;
              if (test) aSons.push({sonId:son._id,listenerId:listener._id});
            });
          });
        }
        if (context.type==='lettre') {
          cachedSons.forEach((son)=>{
            son.listeners.forEach((listener, i) => {
              const tab=listener.hook.split('/');
              let test=false;
              if (tab[0]==='lettre') test=true;
              if (tab[0]==='lettre' && lettres.findIndex((o)=>o._id===tab[1])!==-1) test=true;
              if (test) aSons.push({sonId:son._id,listenerId:listener._id});
            });
          });
        }
      }
      if (mixReady) {
        cachedSons.forEach((son, i) => {
          son.listeners.forEach((listener, i) => {
            const activeIdx=aSons.findIndex((o)=>o.sonId===son._id && o.listenerId===listener._id);
            if (activeIdx===-1) {
              fadeOut(son,listener);
            }
          });
        });
      }
    }
  },[conversations,messages,lettres,themes,mixReady]);
  useEffect(()=>{
    if (muteAll) {
      master.gain.cancelScheduledValues(audioContext.currentTime+0.01);
      master.gain.setValueAtTime(master.gain.value, audioContext.currentTime+0.01);
      master.gain.linearRampToValueAtTime(0, audioContext.currentTime+0.01+0.5);
    } else {
      master.gain.cancelScheduledValues(0);
      master.gain.setValueAtTime(master.gain.value, audioContext.currentTime+0.01);
      master.gain.linearRampToValueAtTime(volume, audioContext.currentTime+0.01+0.5);
    }
  },[muteAll,volume]);
  useEffect(()=>{
    if (settings[0]) {
      setVolume(settings[0].sons.mainVolume);
      master.gain.setValueAtTime(settings[0].sons.mainVolume,audioContext.currentTime);
    }
  },[settings,setVolume]);
  return <MixContext.Provider value={{setContext, triggerSon, loaded, mixReady, muteAll, setMuteAll, sons, players}}>
      {children}
  </MixContext.Provider>;
}
export default MixProvider;
