import React, { useContext,useState,useEffect } from "react";
import "./Conversation.scss";
import { ModelContext } from "providers/ModelProvider";
import { initiales,prenom } from 'shared/conversationsUtils';
import { reactStringReplace } from 'shared/Utils';
import { MixContext } from "shared/providers/MixProvider";

function Conversation({ conversation,step,delta,container,setLien}) {
  const { getDoc,getCollection } = useContext(ModelContext);
  const { triggerSon } = useContext(MixContext);
  const [ currentMessage, setCurrentMessage ] = useState(null);
  const portrait=getDoc(conversation.portraitId);
  const interlocuteurs=[...getCollection('interlocuteur').filter((o)=>conversation.interlocuteurs.indexOf(o._id)!==-1),portrait];
  const messages=getCollection('message').filter((o)=>o.conversationId===conversation._id).sort((a,b)=>a.sort-b.sort);
  const openLien=(e,id)=>{
    e.stopPropagation();
    const l=getCollection('lien').find((o)=>o.slug===id);
    if (l) {
      setLien(l);
    }
  }
  const formatText=(text) => {
    let res=reactStringReplace(text, /(\[([^>\]]+)>>([^\]]+)\])/gm, (matches, i) => {
      //console.log(matches);
      return <span className='lien' onClick={(e)=>openLien(e,matches[2])} key={i}>{matches[1]}</span>;
    });
    return res;
  }

  useEffect(()=>{
    let rank=delta;
    let m=null;
    const messages=getCollection('message').filter((o)=>o.conversationId===conversation._id).sort((a,b)=>a.sort-b.sort);
    messages.forEach((message,i)=>{
      rank++;
      if(step===-1 || step>rank) m=message;
    });
    if (currentMessage===null || currentMessage._id!==m._id) {
      setCurrentMessage(m);
    }
  },[step,conversation._id,delta,setCurrentMessage,currentMessage,getCollection]);
  useEffect(()=>{
    if (currentMessage) {
      triggerSon('message/'+currentMessage._id);
    }
  },[currentMessage,triggerSon]);
  //console.log(messages);
  let rank=delta;
  return <div className='conversation-player-conversation'>
    <div className='conversation-date'>
        {conversation.date}
    </div>
    <div className='conversation-interlocuteurs'>
      {interlocuteurs.map((interlocuteur)=>(
        <div key={interlocuteur._id} className='conversation-interlocuteur'>
          {initiales(interlocuteur.nom)}
        </div>
      ))}
    </div>
    {messages.map((message,i)=>{
      //console.log(interlocuteurs,message);
      rank++;
      const interlocuteur=interlocuteurs.find((o)=>o._id===message.from);
      let to=null;
      if (message.msgType==='missed') to=interlocuteurs.find((o)=>o._id===message.options.missed.to);
      let classes='conversation-message';
      if (message.from===portrait._id) classes+=' conversation-message-right';
      else classes+=' conversation-message-left';
      return step===-1 || step>rank ? <div key={message._id} className={classes}>
        {i===0 || ( messages[i-1].from!==message.from ) ? (
          <div className='conversation-from'>
              {interlocuteur && prenom(interlocuteur.nom)}
          </div>
        ) : ''}
        {i===0 || messages[i-1].from!==message.from || messages[i-1].heure!==message.heure ? (
          <div>{message.heure}</div>
        ) : ''}
        {message.msgType==='default' && <div className='conversation-message-content'>
          <div>
            <div className='conversation-message-bulle'>
              <div className="background-0"></div>
              <div className="background-1"></div>
              <div className="background-2"></div>
              <div className="background-3"></div>
              <div className="text">{formatText(message.options.default.text)}</div>
            </div>
            {message.options.default.failed && <div className='conversation-message-failed'>
              non distribué <span className='failed-icon'></span>
            </div>}
          </div>
        </div>}
        {message.msgType==='missed' && <div className='conversation-message-content'>
          <div className='conversation-message-missed'>
            <span className='missed-call-icon'></span> Appel {to && prenom(to.nom)}
          </div>
        </div>}

      </div> : '';
    })}
  </div>;
}
export default Conversation;
