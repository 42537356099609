import React from "react";
import "./Tapuscrit.scss";
import { Scrollbars } from 'react-custom-scrollbars-2';

function Tapuscrit({lettre,show,onClose}) {
  let classes='tapuscrit-mask';
  if (show) {
    classes+=' on';
  } else classes+=' off';
  const prevent=(e)=>e.stopPropagation();
  const handleClose=(e)=>{
    onClose(e);
  }
  return <div className={classes} onClick={handleClose}>
      <div className='tapuscrit-inner' onClick={prevent}>
        {lettre && <div className='tapuscrit-content'>
          <div className='lettre-surtitre'>Transcription tapuscrite</div>
          <div className='lettre-interlocuteurs'>De : <strong>{lettre.from}</strong><br/> À : <strong>{lettre.to}</strong></div>
          <div className='tapuscrit-text'>
            <Scrollbars
            style={{ position:'absolute', width: '100%', height: '100%' }}
            autoHide
            >
              <div className='tapuscrit-text-inner'>{lettre.tapuscrit}</div>
            </Scrollbars>
          </div>
        </div>}

      </div>
      <div className='tapuscrit-close-btn'></div>
    </div>;
}

export default Tapuscrit;
