class Player {
  constructor (options) {
    this.context=options.audioContext || null;
    this.master=options.master || null;
    this.src=options.src || null;
    this.loop=options.loop || false;
    this._volume=options.volume && !isNaN(options.volume) ? Math.max(0,Math.min(1,options.volume)) : 1;
    this._onend=[];
    this._onfade=[];
    this._onload=[];
    this._onstop=[];
    this.fadeDuration=2000;
    this.fadeTimeout=null;
    this.buffer=null;
    this.loaded=false;
    this.playasked=false;
    this.playing=false;
    this.pausedAt=0;
    this.startedAt=0;
    this.gainNode = this.context.createGain();
    this.gainNode.connect(this.master);
    this.gainNode.gain.value=this._volume;
    this.load();
  }
  get volume() {
    return this._volume;
  }
  set volume(value) {
    this._volume = value;
    this.gainNode.gain.setValueAtTime(value, this.context.currentTime);
    //console.log('volume',value,this.gainNode.gain.value);
  }
  fade(v) {
    if (this.fadeTimeout) clearTimeout(this.fadeTimeout);
    this.gainNode.gain.cancelScheduledValues(this.context.currentTime+0.01);
    //this.gainNode.gain.linearRampToValueAtTime(this._volume, this.context.currentTime + 1);
    //console.log('fade volume',this.gainNode.gain.value);
    this.gainNode.gain.setValueAtTime(this.gainNode.gain.value, this.context.currentTime+0.01);
    this.gainNode.gain.linearRampToValueAtTime(v, this.context.currentTime + 0.01 + this.fadeDuration/1000);
    this.fadeTimeout=setTimeout(()=>{
      this._emit('fade');
    },this.fadeDuration);
  }
  play() {
    if (this.playing) return;
    if (!this.loaded ) {
      this.playasked=true;
    }
    if (this.buffer &&  this.loaded) {
      this.playasked=false;
      this.playing=true;
      this.source = this.context.createBufferSource(); // creates a sound source
      this.source.addEventListener('ended',()=>this._emit('end'));
      this.source.buffer = this.buffer; // tell the source which sound to play
      this.source.loop = this.loop;
      this.source.connect(this.gainNode);
      //console.log('play volume',this.gainNode.gain.value);
      if (this.pausedAt) {
        this.startedAt = Date.now() - this.pausedAt;
        this.source.start(0, this.pausedAt / 1000);
      }
      else {
        this.startedAt = Date.now();
        this.source.start(0,0);
      }
    }
    else this.playasked=true;
  }
  stop() {
    if (!this.playing) return;
    if (this.buffer &&  this.loaded) {
      this.playing=false;
      this.source.stop(0);
      delete this.source;                    // play the source now
      this._emit('stop');
    }
    this.pausedAt = 0;
    this.playasked=false;
  }
  pause() {
    if (!this.playing) return;
    if (this.buffer &&  this.loaded) {
      this.playing=false;
      this.source.stop(0);
      delete this.source;                       // play the source now
    }
    this.pausedAt = Date.now() - this.startedAt;
    this.playasked=false;
  }
  load() {
    var request = new XMLHttpRequest();
    request.open('GET', this.src, true);
    request.responseType = 'arraybuffer';
    request.addEventListener('load', ()=>{
      this.context.decodeAudioData(request.response, (buffer)=>{
        this.buffer=buffer;
        this.loaded=true;
        this._emit('load');
        if (this.playasked) this.play();
      }, function() {
        console.log('audio ctx error');
      });
    })
    request.onerror = function() {
     console.log('errror loading sound')
    }
    request.send();
  }
  on(event, fn, once) {
    var events = this['_on' + event];
    if (typeof fn === 'function') {
      events.push(once ? {fn: fn, once: once} : {fn: fn});
    }
    return this;
  }
  off(event, fn, id) {
    var events = this['_on' + event];
    var i = 0;
    if (fn) {
      // Loop through event store and remove the passed function.
      for (i=0; i<events.length; i++) {
        if (fn === events[i].fn) {
          events.splice(i, 1);
          break;
        }
      }
    } else if (event) {
      // Clear out all events of this type.
      this['_on' + event] = [];
    } else {
      // Clear out all events of every type.
      var keys = Object.keys(this);
      for (i=0; i<keys.length; i++) {
        if ((keys[i].indexOf('_on') === 0) && Array.isArray(this[keys[i]])) {
          this[keys[i]] = [];
        }
      }
    }
    return this;
  }
  once(event, fn) {
    // Setup the event listener.
    this.on(event, fn, 1);
    return this;
  }
  _emit(event, msg) {
    var events = this['_on' + event];
    // Loop through event store and fire all functions.
    for (var i=events.length-1; i>=0; i--) {
      events[i].fn(msg);
      if (events[i].once) {
        this.off(event, events[i].fn);
      }
    }
    return this;
  }
}

export default Player;
