import React, {useContext, useState, useEffect, useLayoutEffect, useRef } from "react";
import "./Landing.scss";
import {
  useNavigate
} from "react-router-dom";
import { ModelContext } from "providers/ModelProvider";
import logo from '../assets/logo.svg';
import PlayerConf from 'shared/Conf';

function Landing() {
  const { getCollection } = useContext(ModelContext);
  const texts=getCollection('interface');
  const text1=texts.find((o)=>o.slug==='landing_bulle1');
  const text2=texts.find((o)=>o.slug==='landing_bulle2');
  const [ step, setStep ] = useState(0);
  const navigate=useNavigate();
  useEffect(()=>{
    if (step>2) navigate('/themes');
  },[step,navigate])
  const container = useRef(null);
  const [ scale, setScale ] = useState(1);
  const [ left, setLeft ] = useState(1);
  const [ top, setTop ] = useState(1);
  useLayoutEffect(()=>{
    const handleResize=()=>{
      if (container.current) {
        const { width,height } = container.current.getBoundingClientRect();
        //console.log(width,height);
        const ratio=height/width;
        const R=PlayerConf.height/PlayerConf.width;
        let scale=1;
        let l=0;
        let t=0;
        if (ratio<R) {
          scale=height/PlayerConf.height;
          l=(width-PlayerConf.width*scale)/2;
        } else {
          scale=width/PlayerConf.width;
          t=(height-PlayerConf.height*scale)/2;
        }
        //console.log(width,height,PlayerConf.width,PlayerConf.height,scale)
        setScale(scale);
        setLeft(l);
        setTop(t);
        const root = document.documentElement;
        root.style.setProperty('--pixelSize', (1.2/scale)+"px");
      }
    }
    window.addEventListener('resize',handleResize);
    handleResize();
    return ()=>{
      window.removeEventListener('resize',handleResize);
    }
  },[setScale,setLeft]);
  return <div className='landing' onClick={()=>setStep(step=>step+1)} ref={container}>
    <div className='landing-mask'></div>
    <div className='logo' style={{left,top,transform:'scale('+scale+')'}}>
      <img src={logo} alt='suppliques stories'/>
      <div className="landing-messages">
        {step>=1 && <div className='landing-message landing-message-right'>
          <div className='landing-message-content'>
            <div className='landing-message-bulle'>
              <div className="background-0"></div>
              <div className="background-1"></div>
              <div className="background-2"></div>
              <div className="background-3"></div>
              <div className="text">{text1 ? text1.texte_long : ''}</div>
            </div>
          </div>
        </div>}
        {step>=2 && <div className='landing-message landing-message-left'>
          <div className='landing-message-content'>
            <div className='landing-message-bulle'>
              <div className="background-0"></div>
              <div className="background-1"></div>
              <div className="background-2"></div>
              <div className="background-3"></div>
              <div className="text">{text2 ? text2.texte_long : ''}</div>
            </div>
          </div>
        </div>}
      </div>
    </div>
  </div>;
}

export default Landing;
