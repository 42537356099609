import React, { useState, useRef, useLayoutEffect, useEffect, useContext } from "react";
import "./ConversationPlayer.scss";
import PlayerConf from 'shared/Conf';
import Conversation from './Conversation';
import Conf from 'Conf';
import { ModelContext } from "providers/ModelProvider";
import { MixContext } from "shared/providers/MixProvider";
import Lien from 'shared/components/Lien';

function ConversationPlayer({ portraitId,step=-1,onTap,follow=true,onDone=()=>null }) {
  const { getDoc,getCollection } = useContext(ModelContext);
  const { triggerSon,mixReady,setContext } = useContext(MixContext);
  const [ lien, setLien ] = useState(null);
  const [ currentConversation, setCurrentConversation ] = useState(null);
  const closeLien=(e)=>{
    e.stopPropagation();
    setLien(null);
  }
  const portrait=getDoc(portraitId);
  const container = useRef(null);
  const mainRef = useRef(null);
  const [ scale, setScale ] = useState(1);
  const [ left, setLeft ] = useState(1);
  useEffect(()=>{
    setContext({type:'portrait',id:portraitId});
    return ()=>{
      setContext(null);
    }
  },[portraitId,setContext]);
  useEffect(()=>{
    if (currentConversation && mixReady) {
      triggerSon('conversation/'+currentConversation._id);
    }
  },[currentConversation,mixReady,triggerSon]);
  useEffect(()=>{
    if (portraitId && mixReady && step===0) {
      triggerSon('portrait/'+portraitId);
    }
  },[portraitId,mixReady,step,triggerSon]);
  useLayoutEffect(()=>{
    const handleResize=()=>{
      if (container.current) {
        const { width,height } = container.current.getBoundingClientRect();
        //console.log(width,height);
        const ratio=height/width;
        const R=PlayerConf.height/PlayerConf.width;
        let scale=1;
        let l=0;
        if (ratio<R) {
          scale=height/PlayerConf.height;
          l=(width-PlayerConf.width*scale)/2;
        } else {
          scale=width/PlayerConf.width;
        }
        //console.log(width,height,PlayerConf.width,PlayerConf.height,scale)
        setScale(scale);
        setLeft(l);
        const root = document.documentElement;
        root.style.setProperty('--pixelSize', (1.2/scale)+"px");
      }
    }
    window.addEventListener('resize',handleResize);
    handleResize();
    return ()=>{
      window.removeEventListener('resize',handleResize);
    }
  },[setScale,setLeft]);
  useEffect(()=>{
    const node=mainRef.current;
    if (follow && node && step>0) {
      const parent=node.parentNode;
      const { height:parentHeight } = parent.getBoundingClientRect();
      const { height } = node.getBoundingClientRect();
      parent.scroll(0,height-parentHeight);
    }
  },[step,follow,getCollection]);
  const conversations=getCollection('conversation').filter((o)=>o.portraitId===portraitId).sort((a,b)=>a.sort-b.sort);
  useEffect(()=>{
    let rank=0;
    let c=null;
    const conversations=getCollection('conversation').filter((o)=>o.portraitId===portraitId).sort((a,b)=>a.sort-b.sort);
    conversations.forEach((conversation,i)=>{
      if(step===-1 || step>rank) c=conversation;
      rank++;
      const msgs=getCollection('message').filter((o)=>o.conversationId===conversation._id).sort((a,b)=>a.sort-b.sort);
      rank+=msgs.length;
    });
    if(step>rank) onDone();
    if (c) {
      if (currentConversation===null || currentConversation._id!==c._id) {
        setCurrentConversation(c);
      }
    } else {
      setCurrentConversation(null);
    }
  },[step,portraitId,setCurrentConversation,currentConversation,onDone,getCollection]);
  let rank=0;
  return portrait ? <>
  <div className='conversation-player-container' onClick={onTap}>
    <div className='conversation-mask'></div>
    <div ref={container} className='conversation-player-scroller'>
      <div className='conversation-player' style={{left,transform:'scale('+scale+')',width:PlayerConf.width+'px'}} ref={mainRef}>
        {portrait.photo[0] && <div className='portrait-photo' style={{backgroundImage:'url('+Conf.filesUrl+portrait.photo[0].url+')'}}/>}
        <div className='portrait-nom'>
          {portrait.nom}
        </div>
        <div className='portrait-chapeau'>
          {portrait.chapeau}
        </div>
        <div className='portrait-separateur'>
        </div>
        {conversations.map((conversation,i)=>{
          if(i>0) {
            const msgs=getCollection('message').filter((o)=>o.conversationId===conversations[i-1]._id);
            rank+=msgs.length+1;
          }
          return step===-1 || step>rank ? <Conversation key={conversation._id} index={i} conversation={conversation} step={step} delta={rank} setLien={setLien}/> : ''
        })}
        {step>0 && <div className='bottom-item'></div>}
      </div>
    </div>
  </div>
  <Lien lien={lien} onClose={closeLien}/>
  </>: '';
}

export default ConversationPlayer;
