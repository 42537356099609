const Conf={
  dbUrl:process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL+"db/model/" : "https://suppliques-back.lefil.org/db/model",
  apiUrl:process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "https://suppliques-back.lefil.org/",
  uploadUrl:process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL+"upload/" : "https://suppliques-back.lefil.org/upload/",
  filesUrl:process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL+"files/" : "https://suppliques-back.lefil.org/files/",
  renewInterval:5*60*1000,
  msgType:[
    {value:'default',label:'Normal'},
    {value:'missed',label:'Appel manqué'},
  ],
  skels:{
    theme:{
      type:'theme',
      titre:''
    },
    portrait:{
      type:'portrait',
      nom:'',
      photo:[],
      chapeau:'',
      themeId:null,
    },
    conversation:{
      type:'conversation',
      date:'',
      messages:[],
      interlocuteurs:[],
      portraitId:null,
    },
    interlocuteur:{
      type:'interlocuteur',
      nom:'',
      prenom:'',
      photo:[],
      portraitId:null,
    },
    message:{
      type:'message',
      heure:'',
      from:null,
      msgType:null,
      options:{
        default:{
          texte:'',
        },
        missed:{
          to:'',
        }
      },
      conversationId:null,
    },
    lettre:{
      type:'lettre',
      titre:'',
      from:'',
      to:'',
      portraitId:null,
    }
  }
}
export default Conf;
