import React, { useContext,useState } from "react";
import "./Lettre.scss";
import { ModelContext } from "providers/ModelProvider";
import { useParams } from "react-router-dom";
import Tapuscrit from 'components/Tapuscrit';
import LettreInner from "shared/components/Lettre";
import {
  useNavigate
} from "react-router-dom";
import { MixContext } from "shared/providers/MixProvider";

function Lettre() {
  const { id } = useParams();
  const { triggerSon } = useContext(MixContext);
  const { getDoc } = useContext(ModelContext);
  const doc=getDoc(id);
  const navigate=useNavigate();
  const [tapuscritShow,setTapuscritShow] = useState(false);
  const [playing,setPlaying] = useState(false);
  const [hide,setHide] = useState(false);
  const togglePlay=()=>{
    if (playing) {
      triggerSon('lettre/'+id+'/stop');
      setPlaying(false);
    } else {
      triggerSon('lettre/'+id+'/play');
      setPlaying(true);
    }
  }
  const hideMenu=()=>setHide(true);
  const showMenu=()=>setHide(false);
  return doc ? <div className={'lettre'+(hide ? ' menu-hide' : '')}>
      <LettreInner id={id} onPointerDown={hideMenu} onPointerUp={showMenu}/>
      <div className='lettre-menu-top'>
        <div className='lettre-menu-inner'>
          <div className='menu-item menu-home' onClick={()=>navigate('/themes')}></div>
          <div className='menu-item menu-portrait' onClick={()=>navigate('/player/'+doc.portraitId)}></div>
          <div className='menu-item menu-settings' onClick={()=>null}></div>
        </div>
      </div>
      <div className='lettre-menu-bottom'>
        <div className='lettre-menu-inner'>
          <div className='menu-item menu-lettres' onClick={()=>navigate('/lettres/'+doc.portraitId)}></div>
          <div className='menu-item menu-tapuscrit' onClick={()=>setTapuscritShow(true)}></div>
          <div className='menu-item menu-plus' onClick={()=>navigate('/plus/'+doc.portraitId)}></div>
          <div className={'menu-item '+(playing ? 'menu-voix-active' : 'menu-voix')} onClick={()=>togglePlay()}></div>
        </div>
      </div>
      <Tapuscrit lettre={doc} show={tapuscritShow} onClose={()=>setTapuscritShow(false)}/>
  </div> : '';
}

export default Lettre;
