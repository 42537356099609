import React, { useContext,useState} from "react";
import "./Player.scss";
import ConversationPlayer from 'shared/components/ConversationPlayer';
import { ModelContext } from "providers/ModelProvider";
import { useParams } from "react-router-dom";
import {
  Link,
} from "react-router-dom";

function Player() {
  const { id } = useParams();
  const { modele,getCollection } = useContext(ModelContext);
  const [ step,setStep ] = useState(0);
  const [ done,setDone ] = useState(false);
  const lettres = getCollection('lettre').filter((o)=>o.portraitId===id);
  const handleTap=()=>{
    setStep(step=>step+1);
  }
  return <div className='player-container'>
      <ConversationPlayer modele={modele} portraitId={id} step={step} onTap={handleTap} onDone={()=>setDone(true)}/>
      {done ? <div className="next-btn-container">
        {lettres.length>0 ? <Link to={'/lettre/'+lettres[0]._id}>Voir la lettre</Link> : 'Terminé'}
      </div> : null}
  </div>;
}

export default Player;
